<template>
  <div>
    <div class="" style="position:relative; width:100%;height:300px;">
      <v-container class="black--text">
        <v-row style="padding:10px">
          <v-col class="header-left">
            <h1>
              {{$t("My Account")}}<br>
            </h1>
            <hr style="border-bottom-color: black !important;width: 100px;margin-top:20px;margin-bottom: 20px;" />
            <p class="black--text"></p>
          </v-col>
          <v-col v-if="false" class="hidden-md-and-down header-right">
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-card width="400px" class="mt-5 mx-auto">
      <v-btn depressed color="info" style="margin-bottom: 10px;" v-if="(showAdmin && isLoggedIn)" @click="openLink('https://admin.stuck-komzet.de/admin/login', true)">
        <v-icon>mdi-account-cog</v-icon>
         {{ $t("nav.admin") }}
      </v-btn>
      <div></div>
      <v-btn v-if="isLoggedIn" depressed color="error" @click="doLogout">
        <v-icon>mdi-power</v-icon>
        {{ $t("nav.logout") }}
      </v-btn>
      <v-card-title style="margin-top: 20px;">
        <h1>{{$t('Profile')}}</h1>
      </v-card-title>
      <v-card-text>
        <v-form value>
          <!--
          <v-text-field
              :label="$t('username')"
              prepend-icon="mdi-account-circle"
              v-model="username"
              :value="email"
              disabled
          />
          -->
          <v-text-field
              :label="$t('email')"
              prepend-icon="mdi-email"
              v-model="email"
              :value="email"
              disabled
          />
          <v-text-field
              :label="$t('firstName')"
              prepend-icon="mdi-account-circle"
              v-model="firstname"
              :value="firstname"
              tabindex="1"
          />
          <v-text-field
              :label="$t('lastName')"
              prepend-icon="mdi-account-circle"
              v-model="lastname"
              tabindex="2"
          />
          <v-text-field
            :label="$t('password')"
            prepend-icon="mdi-lock"
            :type="showLogin ? 'text' : 'password'"
            :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showLogin=!showLogin"
            v-model="password"
            tabindex="3"
          />
          {{$t("password_requirements")}}
          <v-text-field
              :label="$t('password repeat')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password_2"
              tabindex="4"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="info"
          class="mx-auto px-4"
          @click="saveUser"
          tabindex="5"
        >
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="loginFail"
      color="error"
      multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-snackbar
        v-model="loginSuccess"
        color="success"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginSuccessMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
import APIService from "@/services/APIService";
import {mapGetters, mapMutations, mapState} from "vuex";
import EventBus from "@/services/EventBus";
import shared from "@/services/shared";

export default {
  name: "Profile",
  data () {
    return {
      username:'',
      email: '',
      password: '',
      password_2: '',
      firstname: 'abcd',
      lastname: '',
      showLogin: false,
      loginFail: false,
      loginFailMessage: '',
      loginSuccess: false,
      loginSuccessMessage: "",
      isAdminUser: false,
    }
  },
  async mounted() {
    EventBus.$emit('showTopImage', true, 280);
    let res = await APIService.getUserEvents(this.user.id);
    console.log(res.results);
  },
  methods: {
    ...mapMutations(['updateUser',  'updateUserData']),
    ...mapGetters(['isLoggedIn', 'showAdmin']),
    saveUser () {
      if(this.password != "") {
        if (this.password.length < 8 || !shared.validatePassword(this.password)) {
          this.loginFailMessage = this.$t("password_malformed");
          this.loginFail = true;

          return;
        }
        if ((this.password != this.password_2) || this.password == "") {
          this.loginFailMessage = this.$t("passwords_dont_match");
          this.loginFail = true;
          return;
        }
      }
      let user_new = {};
      //user_new = this.user;
      user_new.email = this.user.email;
      //user_new.loginName = this.user.email;
      user_new.password = this.password != "" ? this.password : this.user.password;
      user_new.firstName = this.firstname;
      user_new.lastName = this.lastname;
      user_new.companyName = this.user.companyName;
      user_new.id = this.user.id;
      // eslint-disable-next-line no-unused-vars
      APIService.updateUser(user_new).then((data) => {
        this.loginSuccess = true;
        this.loginSuccessMessage = this.$t("update_succeeded");
        this.user.firstName = user_new.firstName;
        this.user.lastName = user_new.lastName;
        this.user.email = user_new.email;
        this.user.companyName = user_new.companyName;


        this.updateUserData(this.user);

        EventBus.$emit('updateUserEvent');

        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.loginFailMessage = this.$t("update_failed");
        this.loginFail = true;
      })

    },
    setFields: function(){
      this.username = this.user.loginName;
      this.email = this.user.email;
      this.firstname = this.user.firstName;
      this.lastname = this.user.lastName
    },
    validate(value) {
      if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
        return false;
      }
      return true;
    },
    doLogout() {
      this.$store.dispatch('logout').then(() =>{
        shared.goToLink('https://admin.stuck-komzet.de/sso/logout?redirect_uri=https://kurse.stuck-komzet.de', false);
      })
    },
    updateUser() {
      //console.log("is admin? "+shared.userIsAdmin(this.user.role));
      if (this.user !== null && typeof this.user !== 'undefined' && shared.userIsAdmin(this.user.role)) {
        this.isAdminUser = true;
      } else {
        this.isAdminUser = false;
      }
    },
    openLink(url, popup = false) {
      shared.goToLink(url, popup);
    },
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }],
      ...mapState(['user']),
    },
  created() {
    this.setFields();
    this.updateUser();
  },
  beforeRouteUpdate(){
    this.setFields();
  }
}
</script>

<style>
.theme--light.v-card{
  background-color: transparent;
  color: white;
}
.theme--light.v-label--is-disabled, .theme--light.v-label{
  color:white;
}
.theme--light.v-input{
  color:white !important;
}

</style>
